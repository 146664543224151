<template>
  <div
    id="application-header"
    class="navbar navbar-expand-lg navbar-light order-1 bg-white"
  >
    <div class="row no-gutters w-100">
      <div class="col-auto col-lg d-flex justify-content-start">
        <div class="d-flex">
          <div
            :class="[
              'logotype-container d-none',
              {
                'd-lg-flex': !sidebarCollapsed || !showSidebar,
                'sidebar-collapsed': sidebarCollapsed && showSidebar,
                'navbar-sidebar-dark': showSidebar,
              },
            ]"
          >
            <a :href="logoLink" class="navbar-brand brand-large">
              <img
                :src="!showSidebar ? invertedLogoUrl : logoUrl"
                :alt="logoAlt"
              />
            </a>
          </div>

          <div
            :class="[
              'symbol-container d-flex',
              {
                'd-lg-none': !sidebarCollapsed || !showSidebar,
                'sidebar-collapsed': sidebarCollapsed,
                'navbar-sidebar-dark': showSidebar,
              },
            ]"
          >
            <div class="d-lg-none">
              <a :href="logoLink" class="navbar-brand brand-large">
                <img :src="symbolUrl" :alt="logoAlt" />
              </a>
            </div>

            <div class="d-none d-lg-block">
              <a :href="logoLink" class="navbar-brand brand-large">
                <img :src="invertedSymbolUrl" :alt="logoAlt" />
              </a>
            </div>
          </div>

          <div
            v-if="$slots['search-form']"
            class="d-flex align-items-center flex-grow-1 pl-3"
          >
            <slot name="search-form" />
          </div>
        </div>
      </div>

      <div class="col-auto d-flex align-items-center mx-2 mx-md-4 py-3">
        <context-switcher
          v-if="
            $currentUser.confirmed_at &&
            $currentUser.onboarding_marked_as_completed
          "
          :admin-panel-id="adminPanelId"
        />
      </div>

      <div class="col d-flex align-items-center justify-content-end py-3 pr-3">
        <ul class="navbar-nav flex-row align-items-center">
          <li v-if="authenticatedWithMfa" class="nav-item mr-5 mr-lg-4">
            <div
              v-be-tooltip="$t('models.user.tooltips.mfa_authenticated')"
              class="nav-link px-0"
            >
              <i class="fal fa-key" />
            </div>
          </li>

          <template v-if="showActivityLog">
            <li class="nav-item">
              <be-button
                v-be-tooltip="$t('components.activity_log.tabs.my_tasks.title')"
                variant="link"
                class="nav-link nav-link-action px-0 position-relative overflow-visible"
                @click.prevent="toggleActivityLogTab('my-tasks')"
              >
                <i
                  :class="[
                    'fal fa-tasks',
                    {
                      'text-primary':
                        activityLogTab === 'my-tasks' && activityLogOpen,
                    },
                  ]"
                />

                <!-- TODO: Replace the style attribute with utility classes when they are available in Bootstrap -->
                <be-badge
                  v-if="tasks.length > 0"
                  variant="notification"
                  pill
                  class="position-absolute px-1"
                  style="top: 0; right: 0; transform: translate(50%, 0)"
                >
                  {{ tasks.length }}
                </be-badge>
              </be-button>
            </li>

            <li class="nav-item ml-5 ml-lg-4">
              <be-button
                v-be-tooltip="
                  $t('components.activity_log.tabs.recent_activities.title')
                "
                variant="link"
                class="nav-link nav-link-action px-0"
                @click.prevent="toggleActivityLogTab('recent-activities')"
              >
                <i
                  :class="[
                    'fal fa-history',
                    {
                      'text-primary':
                        activityLogTab === 'recent-activities' &&
                        activityLogOpen,
                    },
                  ]"
                />
              </be-button>
            </li>
          </template>

          <li class="nav-item ml-4">
            <be-dropdown
              id="nav-user-dropdown"
              button-class="bg-white text-body p-0 border-0"
              no-caret
              right
            >
              <template #button-content>
                <user-avatar
                  :user="$currentUser.id"
                  size="lg"
                  disable-tooltip
                />

                <span class="d-none d-lg-inline ml-1">
                  {{ $currentUser.name || $currentUser.email }}
                </span>

                <div class="d-none d-lg-inline ml-1">
                  <release-notes-badge />
                </div>

                <i
                  class="fal fa-chevron-down d-none d-lg-inline text-muted ml-2"
                />
              </template>

              <be-dropdown-item
                v-if="$currentAdminPanel && $currentAdminPanel.id"
                href="/home/dashboard"
                item-class="d-flex align-items-center justify-content-between"
              >
                {{ $t("layouts.admin_panel.header.personal_dashboard") }}

                <i class="fal fa-browser fa-fw ml-3 text-muted" />
              </be-dropdown-item>

              <be-dropdown-item
                href="/users/profile"
                item-class="d-flex align-items-center justify-content-between"
              >
                {{ $t("application.my_profile") }}

                <i class="fal fa-cog fa-fw ml-3 text-muted" />
              </be-dropdown-item>

              <be-dropdown-item
                href="/release_notes"
                item-class="d-flex align-items-center justify-content-between"
              >
                {{ $t("activerecord.models.release_note.other") }}

                <div class="ml-3">
                  <release-notes-badge>
                    <i class="fal fa-message-lines fa-fw text-muted" />
                  </release-notes-badge>
                </div>
              </be-dropdown-item>

              <be-dropdown-item
                v-if="$platform.theme.support_url"
                :href="$platform.theme.support_url"
                target="_blank"
                item-class="d-flex align-items-center justify-content-between"
              >
                {{ $t("application.help") }}

                <i class="fal fa-external-link fa-fw ml-3 text-muted" />
              </be-dropdown-item>

              <be-dropdown-item
                id="nav-logout-btn"
                href="/users/sign_out"
                data-method="DELETE"
                item-class="d-flex align-items-center justify-content-between"
                @click="onSignout"
              >
                {{ $t("application.logout") }}

                <i class="fal fa-sign-out fa-fw ml-3 text-muted" />
              </be-dropdown-item>
            </be-dropdown>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ContextSwitcher from "@/components/navbar/ContextSwitcher.vue";
import ReleaseNotesBadge from "@/components/release_notes/ReleaseNotesBadge.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ContextSwitcher,
    ReleaseNotesBadge,
  },

  props: {
    authenticatedWithMfa: {
      type: Boolean,
      required: true,
    },

    showActivityLog: {
      type: Boolean,
      required: true,
    },

    showSidebar: {
      type: Boolean,
      required: true,
    },

    logoConfig: {
      type: Object,
      required: true,
    },

    logoLink: {
      type: String,
      required: true,
    },

    adminPanelId: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    ...mapGetters("session", {
      activityLogTab: "currentActivityLogTab",
      activityLogOpen: "activityLogOpen",
      activities: "getActivities",
      sidebarCollapsed: "collapsedSideNav",
    }),

    ...mapGetters("tasks", ["filterTasks"]),

    tasks() {
      if (this.$currentCompany?.id) {
        return this.filterTasks({
          userId: this.$currentUser.id,
          companyId: this.$currentCompany.id,
          doneAt: false,
          archivedAt: false,
        });
      } else {
        return this.filterTasks({
          userId: this.$currentUser.id,
          doneAt: false,
          archivedAt: false,
        });
      }
    },

    logoAlt() {
      return this.logoConfig?.alt || "Boardeaser";
    },

    logoUrl() {
      return this.logoConfig?.logo_url;
    },

    symbolUrl() {
      return this.logoConfig?.symbol_url;
    },

    invertedLogoUrl() {
      return this.logoConfig?.inverted_url;
    },

    invertedSymbolUrl() {
      return this.logoConfig?.inverted_symbol_url;
    },
  },

  created() {
    this.fetchReleaseNotesUnread();
  },

  methods: {
    ...mapActions("session", [
      "fetchReleaseNotesUnread",
      "toggleActivityLogTab",
    ]),

    onSignout() {
      window.sessionStorage.removeItem("vuex");
    },
  },
};
</script>
