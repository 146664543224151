<template>
  <div :class="cardClasses">
    <div :class="cardHeaderClasses">
      <be-nav tabs class="d-flex flex-column flex-md-row flex-grow-1">
        <be-nav-item
          v-for="item in items"
          :key="item.value"
          :active="activeTab === item.value"
          :item-class="item.class"
          @click="changeTab(item.value)"
        >
          <i
            v-if="item.warning"
            v-be-tooltip="item.warning"
            class="fa-duotone fa-triangle-exclamation fa-fw text-warning"
          />
          {{ item.label }}
          <be-badge v-if="item.count" variant="light" class="badge-pill">
            {{ item.count }}
          </be-badge>
        </be-nav-item>
      </be-nav>

      <div
        v-if="hasContentRightSlot"
        :class="[
          'd-flex flex-column flex-md-row gap-1',
          { 'mt-2 mt-md-0': hasContentRightSlot },
        ]"
      >
        <slot name="content-right" />
      </div>
    </div>
  </div>
</template>

<script>
import RequestQueryMixin from "@/mixins/RequestQuery";

export default {
  name: "BeTabs",

  mixins: [RequestQueryMixin],

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    options: {
      type: Object,
      required: true,
    },

    translationScope: {
      type: String,
      required: false,
      default: "",
    },

    tabId: {
      type: String,
      required: false,
      default: "tab",
    },

    ignoreQueryParams: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  emits: ["update:modelValue"],

  computed: {
    activeTab: {
      get() {
        return this.modelValue;
      },

      set(value) {
        this.$emit("update:modelValue", value);
      },
    },

    items() {
      return Object.values(this.options).map((option) => {
        const translationKey = option.value.replace("-", "_");

        let label = null;
        if (this.translationScope) {
          label = this.$t(`${this.translationScope}.${translationKey}`);
        }

        return {
          label: label,
          count: null,

          ...option,
        };
      });
    },

    cardClasses() {
      let classes = "card d-print-none";

      if (this.showTabs) {
        classes += " mb-2";
      } else {
        classes += " rounded-bottom-0 border-bottom-0";
      }

      return classes;
    },

    cardHeaderClasses() {
      return [
        "card-header",
        "border-bottom-0",
        "rounded",
        "d-lg-flex",
        "justify-content-between",
        "align-items-center",
        {
          "flex-wrap gap-4": this.hasContentRightSlot,
          "border-bottom-0": this.showTabs,
          "py-lg-0": Object.keys(this.options).length > 0,
        },
      ];
    },

    showTabs() {
      return Object.keys(this.options).length > 0;
    },

    hasContentRightSlot() {
      return this.$slots["content-right"];
    },
  },

  watch: {
    activeTab(value) {
      if (this.ignoreQueryParams) {
        return;
      }

      this.setQueryParam(this.tabId, value);
    },

    options: {
      handler() {
        if (!this.validTab(this.activeTab)) {
          this.activeTab = Object.values(this.options).at(0).value;
        }
      },

      deep: true,
    },
  },

  created() {
    if (this.ignoreQueryParams) {
      return;
    }

    const tab = this.getQueryParam(this.tabId);
    if (tab && this.showTabs) {
      this.changeTab(tab);
    }
  },

  methods: {
    changeTab(tab) {
      if (this.validTab(tab)) {
        this.activeTab = tab;
      } else {
        this.activeTab = Object.values(this.options).at(0).value;
      }
    },

    validTab(tab) {
      return Object.values(this.options).some((option) => option.value === tab);
    },
  },
};
</script>
